
import styles from './Tile.module.scss'
import React from "react";
import {Article} from "@/types/Article.d";

// interface TileProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
//     person: Person
// }

export default function Tile({article, baseUrl}: {article: Article, baseUrl: string}) {


    return <article className={`${styles.tile} `}>

        <a className={styles.article} href={`/verhalen/${article.slug}`}>
            <div className={styles.header}>
                <img src={article.image} alt={article.title}/>

            </div>
            <div className={styles.content}>
                <h2>{article.title}</h2>
                <div dangerouslySetInnerHTML={{__html: article.content}}></div>
            </div>
        </a>

        <a className={styles.category} href={`/verhalen/${article.category.slug}`}>{article.category.title}</a>

    </article>
}
